<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0 ml-5 mt-5">
      <h1>
        Api Key de
        <span class="font-weight-light">{{
          selectedCorporative.shortName
        }}</span>
      </h1>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify-md="end" class="mx-8 mx-md-0">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="generate()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3"> fas fa-plus </v-icon>
            Generar Api Key
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :search="search"
          :loading="loading"
          :items="apiKeys"
          sort-by="name"
          :items-per-page="5"
        >
          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="switchControlChanged(item)"
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn color="primary" @click="configureWebhook(item)"
              >Webhook</v-btn
            >
          </template>

          <template v-slot:[`item.secret`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.secret)"
                >
                  {{ item.secret }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.businessId`]="{}">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(selectedCorporative['.key'])"
                >
                  {{ selectedCorporative[".key"] }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.apiKey`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.apiKey)"
                >
                  {{ item.apiKey }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="webhookDialog" v-model="webhookDialog" max-width="900px">
      <webhook
        @success="handleSuccess"
        @cancel="webhookDialog = false"
        :selectedItem="selectedItem"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";
import { customAlphabet } from "nanoid";
import webhook from "./webhook";

import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

export default {
  name: "corporative-keys",
  components: {
    webhook,
    lottie: Lottie,
  },

  mixins: [lottieSettings],

  data() {
    return {
      apiKeys: [],

      snackbar: false,
      snackbarText: "",
      loading: true,
      webhookDialog: false,
      selectedItem: null,
      headers: [
        { value: "name", text: "Nombre" },
        { value: "businessId", text: "Id del comercio" },
        { value: "apiKey", text: "Api Key" },
        { value: "secret", text: "Clave Secreta" },
        { value: "active", text: "Activo" },
        { value: "options", text: "", align: "end" },
      ],
    };
  },
  watch: {
    selectedCorporative() {
      this.apiKeys = [];
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedCorporative", "search", "user"]),
  },
  methods: {
    configureWebhook(key) {
      this.webhookDialog = true;
      this.selectedItem = key;
    },

    handleSuccess() {
      this.webhookDialog = false;
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
    },

    generate() {
      this.loading = true;
      let nanoU = customAlphabet("ABCDEFGHIJKLMNPQRSTUVWXYZ", 10);
      let nanoid = customAlphabet(
        "123456789abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ",
        10
      );

      let mayus = nanoU(7);
      let apiKey = `${mayus}${nanoid(25)}`;

      mayus = nanoU(6);
      let secret = `${mayus}${nanoid(4)}`;

      db.collection("businessesCorporative")
        .doc(this.selectedCorporative[".key"])
        .collection("apiKeys")
        .add({
          apiKey,
          secret,
          createdAt: new Date(),
          cretaedBy: this.user[".key"],
          name: `Api Key ${this.apiKeys.length + 1}`,
          active: true,
          deleted: false,
        })
        .then(() => {
          this.snackbarText = "Llave generada exitosamente.";
          this.snackbar = true;
          this.loading = false;
        })
        .catch(() => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    switchControlChanged(item) {
      this.loading = true;

      let data = {
        active: item.active,
        modifiedAt: new Date(),
        modifiedBy: this.$store.state.user[".key"],
      };

      db.collection("businessesCorporative")
        .doc(this.selectedCorporative[".key"])
        .collection("apiKeys")
        .doc(item[".key"])
        .update(data)
        .then((res) => {
          this.loading = false;
          this.snackbarText = "Información actualizada exitosamente.";
          this.snackbar = true;
        });
    },
    async getBusinessData() {
      if (!this.selectedCorporative) {
        this.$router.push({ path: "/" });
      }

      this.loading = true;

      await this.$binding(
        "apiKeys",
        db
          .collection("businessesCorporative")
          .doc(this.selectedCorporative[".key"])
          .collection("apiKeys")
          .where("deleted", "==", false)
      );

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;
    this.getBusinessData();
  },
};
</script>
