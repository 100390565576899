<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card :loading="loading">
      <v-card-title class="grid-close">
        <span class="headline">Webhook</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                label="URL"
                rounded
                filled
                placeholder="Url"
                v-model="item.webhook.url"
                :loading="loading"
              />
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-select
                rounded
                disabled
                filled
                label="Método"
                placeholder="Método"
                :items="methods"
                :loading="loading"
                v-model="item.webhook.method"
              />
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-select
                rounded
                filled
                label="Tipo de contenido"
                :items="types"
                :loading="loading"
                v-model="item.webhook.type"
              />
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-text-field
                rounded
                :loading="loading"
                filled
                readonly
                label="Llave secreta"
                v-model="item.secret"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="item.webhook.active"
                  :loading="loading"
                  label="Activo"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  @click="save"
                  :loading="loading"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>
  
  <script>
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "webhook",
  props: ["selectedItem"],
  data() {
    return {
      loading: false,
      item: {
        webhook: {},
      },
      snackbar: false,
      snackbarText: "",
      methods: [
        { text: "POST", value: "post" },
        // { text: "GET", value: "get" },
        // { text: "DELETE", value: "delete" },
      ],
      types: [
        { text: "APPLICATION/JSON", value: "application/json" },
        // {
        //   text: "APPLICATION/X-WWW-FORM-URLENCODED",
        //   value: "application/x-www-form-urlencoded",
        // },
        // { text: "XML HTTP REQUEST", value: "text/xml" },
      ],
    };
  },

  computed: {
    ...mapState(["selectedCorporative"]),
  },
  methods: {
    save() {
      if (!this.item.webhook.url) {
        this.snackbarText = "Debe ingresar la url";
        this.snackbar = true;

        return;
      }

      this.loading = true;

      db.collection("businessesCorporative")
        .doc(this.selectedCorporative[".key"])
        .collection("apiKeys")
        .doc(this.item[".key"])
        .update({
          webhook: this.item.webhook,
        })
        .then(() => {
          this.$emit("success");
        })
        .catch((err) => {
          this.snackbarText =
            "Ocurrió un error desconocido, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.item = Object.assign({}, this.selectedItem);

    this.item.webhook = this.item.webhook
      ? this.item.webhook
      : {
          method: "post",
          type: "APPLICATION/JSON",
          active: false,
          url: "",
        };
  },
};
</script>
  
